

import React from "react";
import Header from '../topptekst/Header'; 
import css from "./hjemmeside.module.css";
import logo from "../img/logoYummy.png";
import { BsFillTelephoneFill } from "react-icons/bs";
import { BiTimeFive } from "react-icons/bi";
import { FaMapLocationDot } from "react-icons/fa6";


export default function Hjemmeside() {
    
    return(
        <div className={css.container}>
            <Header/>
                <div className={css.food}>
                    <div className={css.img}>
                        <img src={logo} alt="logo" />
                    </div>
                    <div className={css.img}> 
                    Bienvenue à Yummy 77 où le monde de la gourmandise est à votre portée ! Notre menu est une véritable aventure culinaire allant des pizzas classiques aux tacos savoureux, des cheese naans moelleux aux burgers gourmets, des plats indiens authentiques aux wings et tenders croustillants. Chez Yummy 77, la variété de saveurs est notre spécialité. Venez explorer un festin de choix délicieux!
                    </div>
                </div>
                <div className={css.about} style={{ textAlign: 'right' }}>
                    <div className={css.left}>
                        <span className={css.om}>About us</span><br />
                        <span><FaMapLocationDot /> 94 av. eugène varlin, 77270 villeparisis</span>
                        <span>
                        <span className={css.openingHours}><BsFillTelephoneFill /> 07 64 35 10 49</span>
                        </span><br />
                        <span className={css.openingHours}><BiTimeFive /> Heures d'ouverture</span>
                        <span>Du lundi au samedi 11.00 - 23.00</span>
                    </div>
                    <div className={css.iframe}>
                    <iframe 
                        title="Prins Kebab Location"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2620.3694332651876!2d2.5995453!3d48.946450999999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e616d8b4b8b273%3A0xd315b7e60a12cb70!2s94%20Av.%20Eug%C3%A8ne%20Varlin%2C%2077270%20Villeparisis%2C%20Frankrike!5e0!3m2!1sno!2sno!4v1699147251637!5m2!1sno!2sno"
                        width="800" 
                        height="350" 
                        style={{border:0}} 
                        allowfullscreen="" 
                        loading="lazy" 
                        referrerpolicy="no-referrer-when-downgrade"
                        ></iframe>
                    </div>
                </div>
            </div>
    )
};


import './header.css';  
import { Link } from 'react-router-dom';
import { AiOutlineHome } from "react-icons/ai";
import { MdRestaurantMenu } from "react-icons/md";
import { BsFillTelephoneFill } from "react-icons/bs";

export default function Header() {
    
    return(
        <div className="header">
        
        <div className="takeaway">Order via &nbsp; <BsFillTelephoneFill /> &nbsp; 07 64 35 10 49</div>
        <Link to="/">
            <button className='icons'><AiOutlineHome /></button>
        </Link>
        <Link to="/Menu">
            <button className='icons'><MdRestaurantMenu /></button>
        </Link>
        </div>
    )
};



import './App.css';
import {createBrowserRouter, createRoutesFromElements, Route, Outlet, RouterProvider} from 'react-router-dom'
import Slider from './slider/Hjemmeside';
import Menu from './menu/Menu';


function App() {

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<Root />}>
        <Route index element={<Slider />} />
        <Route path="/Menu" element={<Menu />} />
      </Route>

    )
)

  return (
    <RouterProvider router={router} />
  );
}


export default App;


const Root = () => {
  return <>
    <div>
      <Outlet />
    </div>
  </>
}
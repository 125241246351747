

import "./menu.css";
import Header from "../topptekst/Header";
import Burger from "../img/burger_change_77.jpg";
import Chicken from "../img/yummy_chicken.jpg";
import Family from "../img/yummy_family_buckets.jpg";
import Milkshake from "../img/milkshake_change_77.jpg";
import Naan from "../img/yummy_naan.jpg";
import Pizza from "../img/yummy_pizza.jpg";
import Plats from "../img/yummy_plats.jpg";
import Salad from "../img/salade_pates_change_77.jpg";
import Taco from "../img/yummy_tacos.jpg";
import Kebab from "../img/kebab_change_77.jpg"


export default function Menu() {
    return(
        <div className="container">
            <Header />
            <div className="menu">
                <img src={Burger} alt="Burger" />
                <img src={Chicken} alt="Chicken" />
            </div>
            <div className="menu">
                <img src={Family} alt="Family" />
                <img src={Milkshake} alt="Milkshake" />
            </div>
            <div className="menu">
                <img src={Naan} alt="Naan" />
                <img src={Pizza} alt="Pizza" />
            </div>
            <div className="menu">
                <img src={Plats} alt="Plats" />
                <img src={Salad} alt="Salad" />
            </div>
            <div className="menu">
                <img src={Taco} alt="Taco" />
                <img src={Kebab} alt="Kebab" />
            </div>            
        </div>
    )
};
